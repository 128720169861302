import React, { Suspense, lazy } from 'react';
import './css/bootstrap-4.1.1/bootstrap.min.css'
import './css/font-awesome.min.css'
import './css/icofont.min.css'
import './css/style.css'
import './css/responsive.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loader from './components/common/Loader';
import Careers from './pages/Careers';
const Home = lazy(() => import('./pages/Home'))
const AboutUs = lazy(() => import('./pages/AboutUs'))
const Services = lazy(() => import('./pages/Services'))
const Blog = lazy(() => import('./pages/Blog'))
const Contact = lazy(() => import('./pages/Contact'))
const ServiceDetail = lazy(() => import('./components/services/ServiceDetail'))
const BlogDetails = lazy(() => import('./components/blog/BlogDetails'))

function App() {
  return (  
    <div>
      <BrowserRouter>

        <Suspense fallback={<Loader load={true} />} >
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/careers" element={<Careers />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/services-detail" element={<ServiceDetail />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog-detail" element={<BlogDetails />} />
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
        </Suspense>

      </BrowserRouter>
    </div>
  );
}

export default App;
