import React, { useEffect, useState } from 'react'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import SubBanner from '../components/common/SubBanner'
import ImageSubBanner from '../assets/images/subBanner/blog.webp'
import { MdOutlineVerified } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { useForm } from "react-hook-form"
import { GrGrow, GrView } from "react-icons/gr";
import { GiChart } from "react-icons/gi";
import { RiTeamLine, RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineSafety } from "react-icons/ai";
import { PiShootingStarThin } from "react-icons/pi";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaRegFilePdf } from 'react-icons/fa'; // Import icons from react-icons library
const Careers = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const careerOpening = [
        {
            position: 'Sales Executives',
            description: 'A Sales Executive is a professional who works to promote and sell products or services offered by a company. They engage with potential customers, build relationships, demonstrate the value of the products/services, negotiate terms, and close deals. Their main goal is to achieve sales targets while ensuring customer satisfaction.',
            jobType: 'Full Time',
            locations: [
                {
                    title: 'Coimbatore'
                },
                {
                    title: 'Dharamapuri'
                },
                {
                    title: 'Erode'
                },
                {
                    title: 'Hosur'
                },
                {
                    title: 'Krishnagiri'
                },
                {
                    title: 'Madurai'
                },
                {
                    title: 'Mettupalayam'
                },
                {
                    title: 'Perambalur'
                },
                {
                    title: 'Pollachi'
                }, {
                    title: 'Salem'
                },
                {
                    title: 'Sivakasi'
                },
                {
                    title: 'Srivilliputhur'
                },
                {
                    title: 'Tanjore'
                },
                {
                    title: 'Theni'
                }, {
                    title: 'Trichy'
                },
                {
                    title: 'Virudhunagar'
                },
            ]
        },
        {
            position: 'Sales Telecaller',
            description: `As a Sales Tele caller, your focus is to connect with potential customers through outbound calls. You will introduce loan products, explain their benefits, and guide customers through the application process. Your goal is to effectively qualify leads, address customer inquiries, and convert prospects into satisfied clients. By meeting daily call targets and maintaining accurate records, you play a crucial role in expanding our customer base and contributing to the company's growth.`,
            jobType: 'Full Time',
            locations: [
                {
                    title: 'Coimbatore'
                },
            ]
        },
        {
            position: 'Sales Manager',
            description: 'To build a successful sales team, start by setting clear sales goals and creating detailed job descriptions for each role. Source candidates through different channels, conduct thorough interviews, and check references to ensure they’re a good fit. Communicate the compensation package, offer comprehensive onboarding and training, and set performance expectations. Promote diversity and ethical practices within the team, and provide necessary technology tools for efficiency. Continuously analyze data to refine your strategies and keep the team on track for success.',
            jobType: 'Full Time',
            locations: [
                {
                    title: 'Annur',
                },
                {
                    title: 'Coimbatore'
                },
                {
                    title: 'Dharamapuri'
                },
                {
                    title: 'Erode'
                },
                {
                    title: 'Hosur'
                },
                {
                    title: 'Krishnagiri'
                },
                {
                    title: 'Madurai'
                },
                {
                    title: 'Mettupalayam'
                },
                {
                    title: 'Perambalur'
                },
                {
                    title: 'Pollachi'
                }, {
                    title: 'Salem'
                },
                {
                    title: 'Sivakasi'
                },
                {
                    title: 'Srivilliputhur'
                },
                {
                    title: 'Tanjore'
                },
                {
                    title: 'Theni'
                }, {
                    title: 'Trichy'
                },
                {
                    title: 'Virudhunagar'
                },
            ]
        },
    ]
    const [responseMail, setResponseMail] = useState(false)
    const [uploadResume, setUploadResume] = useState(null)
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        setError,
        formState: { errors },
    } = useForm()

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append('to', 'karpagamurugan@bwefinserv.com');
            formData.append('Name', data?.name || '');
            formData.append('Mail', data?.mail || '');
            formData.append('Phone', data?.phone || '');
            formData.append('subject', 'Career Form');
            formData.append('Position', data?.position);
            formData.append('message', data?.summary || '');
            formData.append('Resume', data?.resume);

            const response = await fetch('https://bwefinserv.com/backend/send_email_career.php', {
                method: 'POST',
                body: formData
            });

            const result = await response.json();

            if (response.ok && result.success) {
                setResponseMail({
                    isSuccess: true,
                    message: result.message
                });
                reset({
                    name: '',
                    mail: '',
                    phone: '',
                    position: '',
                    summary: ''
                });
                setUploadResume(null)
            } else {
                setResponseMail({
                    isSuccess: false,
                    message: result.message
                });
            }
        } catch (error) {
            setResponseMail({
                isSuccess: false,
                message: 'Failed to send mail. Please try again later.'
            });
        }
    };


    useEffect(() => {
        if (responseMail) {
            setTimeout(() => {
                setResponseMail(false)
            }, 5000);
        }
    }, [responseMail])

    const onSelectFile = (e) => {
        if (e) {
            setUploadResume(e)
            setValue('resume', e)
            setError('resume', false)
        }
    }

    const onViewResume = () => {
        if (!uploadResume) {
            alert('file nt found')
        }
        const creatUrl = URL.createObjectURL(uploadResume);
        window.open(creatUrl, '_blank');
    };


    return (
        <div>
            <Header />
            <SubBanner title='Careers' image={ImageSubBanner} />
            <div className="Contributions-section pt-80">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="Contributions-contents">
                                <div className="contribut-sort-dec">
                                    <h2 className="popin">We are hiring!</h2>
                                    <p>We are looking for people who are enthusiastic to be part of a FinTech Start-up journey, learn and grow along with the firm and carve out an excellent career in the Financial Technology Industry! The ideal candidate will possess strong work ethics, an urge to achieve and exceed the goals of the organisation, someone who is looking for opportunities to excel, one who can be pro-active, team player and willing to come up with innovative ideas and solutions.</p>
                                </div>
                                <div className="contribut-items pt-5">
                                    <div className="row">
                                        <div className="col-sm-6 col-lg-6 col-md-6 col-12 sm-50">
                                            <div className="item  career_hire_card">
                                                <div className="contribut-icon"> <MdOutlineVerified /> </div>
                                                <div className="contribut-content"> <a href="/" className="title">Nimble & Agile                                                </a>
                                                    <p>Lack of complex hierarchy & flat company structure</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12 sm-50">
                                            <div className="item  career_hire_card">
                                                <div className="contribut-icon"> <GrGrow />  </div>
                                                <div className="contribut-content"> <a href="/" className="title">Innovate</a>
                                                    <p>Enthusiastic about opportunities to innovate & solve complex business problems.                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12 sm-50">
                                            <div className="item  career_hire_card">
                                                <div className="contribut-icon"> <GiChart />  </div>
                                                <div className="contribut-content"> <a href="/" className="title">Quick Growth</a>
                                                    <p>Exciting learning & growth opportunities across the Group Organisation.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12 sm-50">
                                            <div className="item  career_hire_card">
                                                <div className="contribut-icon"> <RiTeamLine />  </div>
                                                <div className="contribut-content"> <a href="/" className="title">Team Events</a>
                                                    <p>Fun & work must go together.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12 sm-50">
                                            <div className="item  career_hire_card ">
                                                <div className="contribut-icon"> <AiOutlineSafety />  </div>
                                                <div className="contribut-content"> <a href="/" className="title">Insurance Cover                                                </a>
                                                    <p>We have got you covered.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12 sm-50">
                                            <div className="item  career_hire_card">
                                                <div className="contribut-icon"> <PiShootingStarThin />  </div>
                                                <div className="contribut-content"> <a href="/" className="title">Start-up culture                                                </a>
                                                    <p>Where change & learning is the only constant.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="question-contact pt-80">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                            {
                                careerOpening?.map((el, ind) => (
                                    <div className="careers-card mb-3" key={ind}>
                                        <div className="content-mask">
                                            <span className="category">BWE Finserv</span>
                                            <h1>{el?.position}</h1>
                                            <p>{el?.description}</p>
                                            <div className="post-detail">
                                                <span className="date">
                                                    <span className="icon">
                                                        <CiLocationOn /> Job Location
                                                    </span>
                                                    <div className='row'>
                                                        {
                                                            el?.locations?.map((val, index) => (
                                                                <div key={index} className=' col-lg-2 col-md-2 col-sm-2 col-4 location_text'>{val?.title},</div>
                                                            ))
                                                        }
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="careers_apply_form contact-form">
                                <div className="contact-some-dec ">
                                    <h2 className="popin">Apply for this position</h2>
                                </div>
                                <form action="#">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                            placeholder="Enter Your Name...!"
                                            {...register("name", {
                                                required: 'Name is required',
                                                pattern: {
                                                    value: /^[A-Za-z -]+$/,
                                                    message: "Invalid name format"
                                                }
                                            })}
                                        />
                                        {errors.name?.message && <p className='error-message'>{errors.name?.message}</p>}
                                    </div>
                                    <div className="form-group">
                                        <input type="number" className="form-control"
                                            placeholder="Enter Your Phone Number...!"
                                            {...register("phone", {
                                                required: 'Phone Number is required',
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: "Only numbers are allowed"
                                                }
                                            })}
                                        />
                                        {errors.phone?.message && <p className='error-message'>{errors.phone?.message}</p>}
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control"
                                            placeholder="Enter Your Mail...!"
                                            {...register("mail", {
                                                required: 'Mail is required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: 'Invalid email address'
                                                }
                                            })}
                                        />
                                        {errors.mail?.message && <p className='error-message'>{errors.mail?.message}</p>}
                                    </div>
                                    <div className="form-group">
                                        <select type="select" className="form-control"
                                            {...register("position", { required: 'Please Select the position' })}
                                        >
                                            <option value=''>Select position</option>
                                            {
                                                careerOpening?.map((el, ind) => (
                                                    <option key={ind} value={el?.position}>{el?.position}</option>
                                                ))
                                            }
                                        </select>
                                        {errors.position?.message && <p className='error-message'>{errors.position?.message}</p>}
                                    </div>
                                    <div>
                                        {
                                            uploadResume ?
                                                <div className='uploaded_file'>
                                                    <h5 className='upload_file_td'><FaRegFilePdf /> {uploadResume.name.slice(0, 20)}...</h5>
                                                    <div>
                                                        <GrView onClick={onViewResume} className='view_pdf' />
                                                        <RiDeleteBin6Line className='dlt_pdf' onClick={() => {
                                                            setValue('bankStatement', ''); setUploadResume(null);
                                                        }} />
                                                    </div>
                                                </div>
                                                :
                                                <div className='parse_input_statement'>
                                                    <div >
                                                        <input id="fileUpload" style={{ display: 'none' }} onInput={(e) => onSelectFile(e?.target?.files[0])} className="d-d-hide" {...register("resume", { required: 'Resume is required' })} type='file' accept='.pdf' />
                                                        <label htmlFor="fileUpload" style={{ cursor: 'pointer' }}>
                                                            <IoCloudUploadOutline /><br />
                                                            Upload Your CV
                                                        </label>
                                                    </div>
                                                </div>
                                        }
                                        {errors.resume?.message && <p className='error-message'>{errors.resume?.message}</p>}
                                    </div>


                                    <div className="form-group">
                                        <textarea className="form-control form-message" rows="5"
                                            type='text'
                                            placeholder="Enter Your Name...!"
                                            {...register("summary", { required: 'Summary is required' })}
                                        />
                                        {errors.summary?.message && <p className='error-message'>{errors.summary?.message}</p>}
                                    </div>
                                    <div className="button form-group career_ap_btn">
                                        <button onClick={handleSubmit(onSubmit)} className="btn btn-button btn-button-1 blue-bg">Apply now</button>
                                    </div>
                                    {
                                        responseMail &&
                                        <div class={`alert mt-3 ${responseMail?.isSuccess === true ? 'alert-success' : 'alert-danger'}`} role="alert">
                                            {responseMail?.message}
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Careers
