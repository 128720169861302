import React from 'react'

const Loader = ({ load }) => {
    return ((
        load ?
            <div className="preloader">
                <div className="data-loader">
                    <div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            : <></>
    ))
}

export default Loader
