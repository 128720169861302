import React from 'react'

const SubBanner = ({ title, image }) => {
    return (
        <div className="breadcrumbs">
            <div className='overlay'></div>
            <div className="bread-bg" style={{ backgroundImage: `url(${image})`, }}>
                <div className="container">
                    <div className="breadcrum-inner d-flex align-items-center text-center justify-content-center">
                        <h2 className="popin">{title}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubBanner
