import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo/BWE_logo.webp'
import { Link } from 'react-router-dom'

const Header = () => {

    const [isMenuShow, setIsMenuShow] = useState(false)
    useEffect(() => {
        const images = [logo]; // Array of image paths

        images.forEach((image) => {
            const img = new Image();
            img.src = image;
        });
    }, []);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let temp = document.getElementById('menuHeader')
            if (window.scrollY > 200) {
                temp.classList.add('sticky-menu')
            } else {
                temp.classList.remove('sticky-menu')
            }
        })
    })




    return (
        <div>
            <header className="header-section ">
                <div className="header-top p-1 ">
                    <div className="container">
                        <div className="row">
                            <div
                                className="d-flex align-items-center justify-content-center sm-50 c-center c-right">
                                <div className="call-num h-top-item">
                                    <p><span className="fa fa-phone"></span><a href="tel:example@example.com">73059 55237</a></p>
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end c-center sm-hidden">
                                <div className="social">
                                    <ul>
                                        <li><a href="/"><span className="fa fa-facebook"></span></a></li>
                                        <li><a href="/"><span className="fa fa-twitter"></span></a></li>
                                        <li><a href="/"><span className="fa fa-pinterest"></span></a></li>
                                        <li><a href="/"><span className="fa fa-linkedin"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-center p-2 brand_sec">
                    <div className="container">
                        <div className="row">
                            <div className="brand_logo_sec">
                                <div className="logo"> <Link to="/"> <img loading="lazy"  fetchpriority="low" src={logo} width="300" height="200" alt="img" /> </Link> </div>
                            </div>
                            <div className="d-flex align-items-center c-center">
                                <div className="working-time">
                                    <p><span className="blue-c">Operating Hours :</span>Monday To Saturday | 10 am - 6 pm</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-menu" id='menuHeader'>
                    <div className="container">
                        <nav className="navbar navbar-expand-md btco-hover-menu main_menu">

                            <button onClick={() => setIsMenuShow(!isMenuShow)} className="navbar-toggler" type="button">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className={`collapseMenu navbar-collapse ${isMenuShow === true ? 'showMenu' : ''}`} id="navmenu">
                                <ul className="navbar-nav">
                                    <li><Link className='dropdown-item' to='/'>home</Link></li>
                                    <li><Link className='dropdown-item' to='/about-us'>About Us</Link></li>
                                    <li><Link className='dropdown-item' to='/careers'>Careers</Link></li>
                                    {/* <li><Link className='dropdown-item' to='/services'>Services</Link></li> */}
                                    {/* <li><Link className='dropdown-item' to='/blog'>blog</Link></li> */}
                                    <li><Link className='dropdown-item' to='/contact'>Contact</Link></li>
                                </ul>
                            </div>

                        </nav>
                    </div>
                </div >
            </header >
        </div >
    )
}

export default Header
