import React, { useEffect } from 'react'
import logo from '../../assets/images/logo/BWE_logo.webp'
import { Link } from 'react-router-dom'
import { SlArrowUp } from "react-icons/sl";

const Footer = () => {

  useEffect(() => {
    const images = [logo]; // Array of image paths

    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  const OnScrollTop = () => {
    const container = document.querySelector('.scroll-container');
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let temp = document.getElementById('scrollTop')
      if (window.scrollY < 250) {
        temp.classList.add('d-none')
      } else {
        temp.classList.remove('d-none')
      }
    })
  })

  return (
    <div>
      <SlArrowUp onClick={OnScrollTop} id='scrollTop' className='scroll_top' />
      {/* <div className="subscrib-area pt-80">
        <div className="blue-bg p-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-8 col-12 d-flex align-items-center c-center">
                <div className="subscrib-dec">
                  <h2>Subscribe to our Newsletter.</h2>
                </div>
              </div>
              <div
                className="col-xl-5 col-lg-5 col-md-5 col-sm-4 col-12 d-flex align-items-center justify-content-end c-center">
                <div className="button"> <a href="/" className="btn btn-button btn-button-1 popin">Subscribe</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <footer className="footer-section black-bg">
        <div className="footer-inner p-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="ft-content">
                  <div className="ft-info">
                    <div className="ft-logo pb-4"> <img loading="lazy"  fetchpriority="low" src={logo} width="300" height="200" alt="img" /> </div>
                    <div className="ft-info-content">
                      <p>BWE is a FinTech Platform born through the collaboration of like-minded people to solve the burning problems of borrowers and lenders.</p>
                      <ul className="pt-3">
                        <li><i className="fa fa-envelope-o"></i> <label><a className='brand_mail' href='mailto:contact@bwefinserv.com'>contact@bwefinserv.com</a></label> </li>
                        <li><i className="icofont">world</i> <label>https://bwefinserv.com</label> </li>
                      </ul>
                      <div className="social pt-3">
                        <ul>
                          <li><a href="/"><span className="fa fa-facebook"></span></a></li>
                          <li><a href="/"><span className="fa fa-twitter"></span></a></li>
                          <li><a href="/"><span className="fa fa-pinterest"></span></a></li>
                          <li><a href="/"><span className="fa fa-linkedin"></span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="ft-widget">
                  <div className="ft-widget-content">
                    <h2 className="ft-title">Category</h2>
                    <ul className="pt-3">
                      <li><Link to='/'>home</Link></li>
                      <li><Link to='/about-us'>About Us</Link></li>
                      <li><Link to='/careers'>Careers</Link></li>
                      {/* <li><Link to='/services'>Services</Link></li> */}
                      {/* <li><Link to='/blog'>blog</Link></li> */}
                      <li><Link to='/contact'>Contact</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="ft-widget">
                  <div className="ft-widget-content">
                    <div className="ft-twite">
                      <div className="ft-twite-top"> <a href="/">Operating Hours</a>
                        <div className="twite-date">10:00 AM - 06:00 PM</div>
                      </div>
                      <p>BWE’s vision focuses on providing a seamless, end to end digital loan process without any manual intervention. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright pb-1 pt-2">
            <div className="container al-c">
              <p>Copyright &copy; 2024 All Rights Reserved <a href="/" className="blue-c"> BWE Financial Service LLP</a></p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
